import { Box, Text } from 'theme-ui';
import Section from './Section';
import { CImage } from 'components/ui/CImage';
import { Link } from 'components/ui/links';

export default function AboutUs() {
  return (
    <Section
      title="About Us"
      heading="Meet the Team"
      intro="We’re three Canadians with a passion for good design. After struggling to make our own resumes, we set out to make it easy for anyone to write and design their resume."
    >
      <Box
        variant="layout.content"
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Person
          name="Sinan Maani"
          title="Designer"
          imageId="landing-pages/sinan.png"
          linkText="rsm.io/sinan"
          url="https://standardresume.co/r/sinan"
        />
        <Person
          name="Riley Tomasek"
          title="Developer"
          imageId="landing-pages/rt.png"
          linkText="rsm.io/rileyt"
          url="https://standardresume.co/r/rileyt"
        />
        <Person
          name="Sari Maani"
          title="Designer"
          imageId="landing-pages/sari.png"
          linkText="rsm.io/sari"
          url="https://standardresume.co/r/sari"
        />
      </Box>
    </Section>
  );
}

function Person({
  name,
  title,
  imageId,
  linkText,
  url,
}: {
  name: string;
  title: string;
  imageId: string;
  linkText: string;
  url: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: [48, 0],
      }}
    >
      <CImage
        alt="Standard Resume Founder"
        htmlWidth={556}
        htmlHeight={536}
        id={imageId}
        loading="lazy"
        sx={{
          width: 278,
          height: 268,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 14,
        }}
      >
        <Text
          variant="displayXSmall"
          sx={{
            fontWeight: 'semibold',
          }}
        >
          {name}
        </Text>
        <Text
          variant="displayXSmall"
          sx={{
            color: 'textLighter',
            fontWeight: 'medium',
            mb: 16,
          }}
        >
          {title}
        </Text>
        <Link
          href={url}
          sx={{
            color: 'pink',
            fontFamily: 'monospace',
            textTransform: 'uppercase',
          }}
        >
          {linkText}
        </Link>
      </Box>
    </Box>
  );
}
