import { Box } from 'theme-ui';
import AboutUs from './AboutUs';
import GetStarted from './GetStarted';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import OptimizedDesigns from './OptimizedDesigns';
import Pricing from './Pricing';
import ResumeBuilder from './ResumeBuilder';
import TrustedTemplates from './TrustedTemplates';
import WebResume from './WebResume';

export default function TechLandingPage() {
  return (
    <Box>
      <Hero />
      <HowItWorks />
      <TrustedTemplates />
      <OptimizedDesigns />
      <ResumeBuilder />
      <WebResume />
      <AboutUs />
      <Pricing />
      <GetStarted />
    </Box>
  );
}
