import { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Box, Text } from 'theme-ui';
import Typewriter from 'typewriter-effect';
import { CImage, getSrcSet } from 'components/ui/CImage';
import { usePrefersReducedMotion } from 'hooks/use-is-media-query';
import { sizes } from 'styles/theme';

const HERO_IMG_SIZES = '(max-width: 1063px) 100vw, 1031px';
const HERO_IMG_SRCSET_SIZES = [375, 750, 1838];

export default function Hero() {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [index, setIndex] = useState(0);

  const DATA = getData();

  // Preoload images 2...n for smoother animations.
  useEffect(() => {
    for (let i = 1; i < DATA.length; i++) {
      const img = new Image();
      img.sizes = HERO_IMG_SIZES;
      img.srcset = getSrcSet(DATA[i].imageId, HERO_IMG_SRCSET_SIZES, {});
    }
  }, []);

  return (
    <Box
      variant="layout.content"
      sx={{
        maxWidth: [sizes.mobileMaxWidth, 1063],
        mt: [32],
        mb: [48, 80, 117],
      }}
    >
      <Text
        as="h1"
        variant="displayLarge"
        sx={{ textAlign: 'center', mb: [32] }}
      >
        Impressive Resumes for <br />
        <Text
          as="span"
          variant="displayLarge"
          sx={{
            '.Typewriter': {
              minHeight: [29, 44, 69],
            },
            '.Typewriter__wrapper': {
              background:
                'linear-gradient(88.28deg, #0094FF 16.83%, #9B8FFE 44.77%, #FF33DE 71.88%, #FF60E6 98.01%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            },
            '.Typewriter__cursor': {
              WebkitTextFillColor: '#FF5DE5',
            },
          }}
        >
          {prefersReducedMotion ? (
            <div className="Typewriter">
              <div className="Typewriter__wrapper">Everyone</div>
            </div>
          ) : (
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 100,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('Software Engineers')
                  .pauseFor(200)
                  .callFunction(() => {
                    setIndex(1);
                  })
                  .deleteAll(30)
                  .typeString('iOS Developers')
                  .pauseFor(2500)
                  .callFunction(() => {
                    setIndex(2);
                  })
                  .deleteAll(30)
                  .typeString('Digital Marketers')
                  .pauseFor(2500)
                  .callFunction(() => {
                    setIndex(3);
                  })
                  .deleteAll(30)
                  .typeString('Web Developers')
                  .pauseFor(2500)
                  .callFunction(() => {
                    setIndex(4);
                  })
                  .deleteAll(30)
                  .typeString('Product Designers')
                  .pauseFor(2500)
                  .callFunction(() => {
                    setIndex(0);
                  })
                  .deleteAll(30)
                  .start();
              }}
            />
          )}
        </Text>
      </Text>
      <SwitchTransition>
        <CSSTransition
          key={index}
          // @ts-ignore this is from the docs...
          addEndListener={(node: HTMLElement, done: () => void) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames="hero"
        >
          <CImage
            id={DATA[index].imageId}
            alt={DATA[index].imageAlt}
            htmlWidth={1838}
            htmlHeight={1231}
            sizes={HERO_IMG_SIZES}
            srcSetSizes={HERO_IMG_SRCSET_SIZES}
            preload={index === 0}
            transformations={{
              quality: index === 0 ? 30 : 'auto',
            }}
          />
        </CSSTransition>
      </SwitchTransition>
      <style>
        {`
          .hero-enter{
             opacity: 0;
          }
          .hero-exit{
             opacity: 1;
          }
          .hero-enter-active{
             opacity: 1;
             transition: all 600ms ease-in;
             transition-delay: 150ms;
          }
          .hero-exit-active{
             opacity: 0;
             transition: all 300ms ease-out;
             transition-delay: 600ms;
          }
        `}
      </style>
    </Box>
  );
}

function getData() {
  let data = [
    {
      title: 'Software Engineers',
      imageId: 'landing-pages/software-engineer-resume-template.png',
      imageAlt: 'Software Engineer PDF resume template and web resume',
    },
    {
      title: 'iOS Developers',
      imageId: 'landing-pages/ios-developer-resume-template.png',
      imageAlt: 'iOS Developer PDF resume template and web resume',
    },
    {
      title: 'Digital Marketers',
      imageId: 'landing-pages/digital-marketer-resume-template.png',
      imageAlt: 'Digital Marketer PDF resume template and web resume',
    },
    {
      title: 'Web Developers',
      imageId: 'landing-pages/web-developer-resume-template.png',
      imageAlt: 'Web Developer PDF resume template and web resume',
    },
    {
      title: 'Product Designers',
      imageId: 'landing-pages/ux-designer-resume-template.png',
      imageAlt: 'UX Designer PDF resume template and web resume',
    },
  ];
  return data;
}
