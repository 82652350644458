import { Box, Text } from 'theme-ui';
import Section from './Section';
import { LinkButtonArrow } from 'components/ui/links';
import { sizes } from 'styles/theme';

export default function HowItWorks() {
  return (
    <Section title="How it works" heading="3 Steps. 5 Minutes.">
      <Box
        variant="layout.content"
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          maxWidth: [sizes.mobileMaxWidth, 1280],
        }}
      >
        <Step>
          <NumberCircle number="1" color="#2CE5AE" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text variant="displayXSmall">Import from LinkedIn</Text>
            <Text sx={{ color: 'textLighter' }}>Or start from scratch</Text>
          </Box>
        </Step>
        <Step>
          <NumberCircle number="2" color="#41D1FF" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text variant="displayXSmall">Choose a template</Text>
            <Text sx={{ color: 'textLighter' }}>
              12 Hiring manager approved templates
            </Text>
          </Box>
        </Step>
        <Step>
          <NumberCircle number="3" color="#F472FF" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text variant="displayXSmall">Share as web or PDF</Text>
            <Text sx={{ color: 'textLighter' }}>
              A resume for every application
            </Text>
          </Box>
        </Step>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: [32, 64] }}>
        <LinkButtonArrow
          href={`${process.env.NEXT_PUBLIC_APP_SETUP_URL}`}
          sx={{
            bg: 'sBrandBlue',
            color: 'sWhite',
            'svg path': { stroke: 'sWhite' },
          }}
        >
          Build My Resume
        </LinkButtonArrow>
      </Box>
    </Section>
  );
}

function Step({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: [18, 0],
        ml: [36, 0],
      }}
    >
      {children}
    </Box>
  );
}

function NumberCircle({ number, color }: { number: string; color: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'monospace',
        fontWeight: 300,
        color,
        fontSize: [18, 24],
        width: 48,
        height: 48,
        borderRadius: 999,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: color,
        mr: 16,
      }}
    >
      {number}
    </Box>
  );
}
