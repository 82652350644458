import { Box, Text } from 'theme-ui';
import Section from './Section';
import { Card, CardContainer } from 'components/ui/cards';
import { ArrowRightIcon, HeartIcon } from 'components/ui/icons';

export default function Pricing() {
  return (
    <Section title="Pricing" heading="Simple, Fair Pricing">
      <CardContainer>
        <Card
          column={[12, 6]}
          sx={{
            background: 'transparent',
            borderColor: 'sButtonGrayLight',
            borderStyle: 'solid',
            borderWidth: 1,
            py: 56,
            px: [32, 48],
          }}
        >
          <Text variant="displayXSmall" sx={{ mb: 32 }}>
            Web Only
          </Text>
          <Text variant="displayLarge" sx={{ mb: 24 }}>
            $0
          </Text>
          <Feature>Import from LinkedIn</Feature>
          <Feature>Resume editing & PDF preview</Feature>
          <Feature>Web resume sharing</Feature>
        </Card>
        <Card
          column={[12, 6]}
          sx={{
            bg: 'sBlack',
            py: 56,
            px: [32, 48],
          }}
        >
          <Text variant="displayXSmall" sx={{ mb: 32, color: 'pink' }}>
            Web and PDF
          </Text>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Text variant="displayLarge" sx={{ mb: 24, pr: 4 }}>
              $19
            </Text>
            <Text variant="title">/ month</Text>
          </Box>
          <Feature>PDF resume downloads</Feature>
          <Feature>Web resume view tracking</Feature>
          <Feature>Remove Standard Resume branding</Feature>
          <Feature isHeart={true}>Support a small team</Feature>
        </Card>
      </CardContainer>
    </Section>
  );
}

function Feature({
  children,
  isHeart = false,
}: {
  children: React.ReactNode;
  isHeart?: boolean;
}) {
  return (
    <Text
      sx={{
        display: 'flex',
        alignItems: ['flex-start', 'center'],
        my: 4,
        color: 'text',
      }}
    >
      {isHeart ? (
        <HeartIcon color="text" mr={8} mt={[-4, 0]} />
      ) : (
        <ArrowRightIcon color="text" mr={8} mt={[-4, 0]} />
      )}
      {children}
    </Text>
  );
}
